<template>
  <div style="height: 100vh">
    <div v-show="showPdf == true">
      <!-- invoivce -->
      <div id="pdfportion">
        <div style="font-family: 'Acme', 'Lobster', 'Open Sans', sans-serif; font-size: 30px; font-style: italic; text-align: center; justify-content: center; padding-top: 10px; vertical-align: bottom">
          <div>
            <img :src="logo" style="max-width: 50px; left: 0" />
          </div>
          <div style="vertical-align: bottom; margin-top: -2vh">
            {{ company.company_name }}
          </div>
        </div>
        <div style="display: flex; justify-content: center">
          <div>
            <strong> </strong>
          </div>
        </div>
        <div style="display: flex; justify-content: center">
          <div>
            <!-- 123/5, BCS Cmputer City, IDB Bhaban -->
            {{ company.company_address }}
          </div>
        </div>
        <div style="display: flex; justify-content: center">Hotline: {{ company.contact_person_phone }}</div>
        <div class="columns">
          <div class="column is-12">
            <div style="float: left; margin-left: 10px; font-size: 18px">
              <p style="font-weight: 700">Date: {{ calcDate }}</p>
            </div>
            <div style="float: right; margin-right: 10px; font-size: 18px">
              <p style="font-weight: 700">Time: {{ calcTime }}</p>
            </div>
          </div>
        </div>
        <div class="columns">
          <div class="column is-12" style="border-top: 1px solid #dbdbdb; border-bottom: 1px solid #dbdbdb">
            <div style="justify-content: left; float: left; margin-left: 10px; font-size: 18px; font-weight: 600">
              <p style="">Name: {{ custInfo.gender }} {{ custInfo.first_name }} {{ custInfo.last_name }}</p>
              <p>Phone: {{ custInfo.phone }}</p>
            </div>
            <div style="justify-content: left; float: right; margin-right: 10px; font-size: 18px; font-weight: 600">
              <p>Card No: {{ cardData.card_no }}</p>
            </div>
          </div>
        </div>

        <table class="table is-fullwidth">
          <thead>
            <tr>
              <th>Item</th>
              <th>Date</th>
              <th>Qnty</th>
              <th>Cost</th>
              <th>Discount/ S. Discount</th>
              <th>Final Trans</th>
            </tr>
          </thead>
          <tbody style="font-size: 15px">
            <tr v-for="bill in billData" :key="bill.id">
              <template v-if="bill.bill_item == 'Room Clearance'">
                <td>Payment ({{ bill.trx_type }})</td>
              </template>
              <template v-else>
                <td>{{ bill.bill_item }}</td>
              </template>
              <td>{{ bill.date }}</td>
              <template v-if="bill.bill_category == 'debit'">
                <td>{{ bill.item_quantity }}</td>
              </template>
              <template v-else>
                <td>N.A</td>
              </template>
              <template v-if="bill.bill_category == 'debit' && bill.type_bill == 'room'">
                <td>{{ (bill.cost / bill.item_quantity).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') }}</td>
              </template>
              <template v-else-if="bill.bill_category == 'debit' && bill.type_bill == 'laundry'">
                <td>{{ bill.cost.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') }}</td>
              </template>
              <template v-else-if="bill.bill_category == 'debit' && bill.type_bill == 'room_service'">
                <td>{{ bill.cost.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') }}</td>
              </template>
              <template v-else>
                <td>{{ bill.cost.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') }}</td>
              </template>
              <template v-if="bill.bill_category == 'debit'">
                <td>{{ bill.discount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') }} (0%)</td>
              </template>
              <template v-else>
                <td>{{ bill.special_discount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') }}</td>
              </template>
              <template v-if="bill.bill_category == 'debit'">
                <td>{{ ((bill.cost / bill.item_quantity) * bill.item_quantity).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') }}</td>
              </template>
              <template v-else>
                <td>(-){{ bill.cost.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') }}</td>
              </template>
            </tr>
          </tbody>
        </table>
        <hr style="margin-top: -20px" />

        <!-- <div style="float:right; font-size:18px; margin-right:10px; font-weight:bold;">Total Bill: {{resInfo.total_amount}}</div> -->

        <div style="text-align: right; font-size: 18px; margin-right: 10px">Sub Total: {{ (parseInt(roomCost) + laundryCalculated + roomServiceCalculated).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') }} BDT</div>

        <div style="text-align: right; font-size: 18px; margin-right: 10px">VAT({{ companyInfo.tax }}%): {{ (roomCost * (companyInfo.tax / 100)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') }} BDT</div>

        <div style="text-align: right; font-size: 18px; margin-right: 10px">Service Charge({{ companyInfo.service_charge }}%): {{ (roomCost * (companyInfo.service_charge / 100)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') }} BDT</div>

        <template v-if="laundryVatAmount != 0 || laundryServiceCharge != 0">
          <div style="text-align: right; font-size: 18px; margin-right: 10px">Laundry VAT & SD({{ laundryVatAmount }}% | {{ laundryServiceCharge }}%): {{ laundryCalculatedDuty.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') }} BDT</div>
        </template>
        <template v-if="roomServiceVat != 0 || roomServiceServiceCharge != 0">
          <div style="text-align: right; font-size: 18px; margin-right: 10px">RoomService VAT & SD({{ roomServiceVat }}%|{{ roomServiceServiceCharge }}%): {{ roomServiceCalculatedDuty.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') }} BDT</div>
        </template>

        <div style="text-align: right; font-size: 18px; margin-right: 10px">Discount(s):(-) {{ resInfo.previous_discounts.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') }} BDT</div>

        <p style="text-align: right; margin-right: 10px; margin-top: -20px; font-size: 18px">Grand Total: {{ (laundryCalculated + roomServiceCalculated + laundryCalculatedDuty + roomServiceCalculatedDuty + roomCost + roomCost * (companyInfo.tax / 100) + roomCost * (companyInfo.service_charge / 100) - resInfo.previous_discounts).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') }} BDT</p>

        <p style="text-align: right; margin-right: 10px; margin-top: -20px; font-size: 18px">Advance & Payment(s): (-) {{ (advanceCalculated + roomClearanceCalculated).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') }} BDT</p>

        <template v-if="resInfo.total_due != 0">
          <hr />
          <p style="text-align: right; margin-right: 10px; margin-top: -20px; font-size: 18px">Total Due(s): {{ (laundryCalculated + roomServiceCalculated + laundryCalculatedDuty + roomServiceCalculatedDuty + roomCost + roomCost * (companyInfo.tax / 100) + roomCost * (companyInfo.service_charge / 100) - resInfo.previous_discounts - advanceCalculated - roomClearanceCalculated).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') }} BDT</p>
        </template>
        <template v-else>
          <hr />
          <p style="text-align: right; margin-right: 10px; margin-top: -20px; font-weight: bold; font-size: 18px; color: red">PAID</p>
        </template>
      </div>
    </div>
    <div class="columns" style="">
      <div class="column is-12">
        <div class="box" style="font-size: 22px; font-weight: bold; height: 60px; width: 100%">
          <p style="float: left; margin-top: -5px">Checkout</p>
          <button class="button" style="float: right; background-color: #099dd9; color: white; margin-top: -10px; margin-left: 5px" @click.prevent="submitCheckOut()">Submit</button>
          <div v-if="fromId == '' || fromId == null">
            <button class="button" style="float: right; margin-top: -10px"><router-link :to="{ name: 'ReservationEdit', params: { id: resInfo.id } }" style="color: black">Back</router-link></button>
          </div>
          <div v-else>
            <button class="button" style="float: right; margin-top: -10px"><router-link :to="{ name: 'Home' }" style="color: black">Back</router-link></button>
          </div>
        </div>
      </div>
    </div>
    <div class="columns">
      <div class="column is-6">
        <div class="box" style="height: 87vh; margin-left: 10px; margin-right: -5px; overflow-y: auto">
          <table class="table is-fullwidth">
            <thead>
              <tr>
                <th>Item</th>
                <th>Date</th>
                <th>Qnty</th>
                <th>Cost</th>
                <th>Discount/ S. Discount</th>
                <th>Final Trans</th>
              </tr>
            </thead>
            <tbody style="font-size: 15px">
              <tr v-for="bill in billData" :key="bill.id">
                <template v-if="bill.bill_item == 'Room Clearance'">
                  <td>Payment ({{ bill.trx_type }})</td>
                </template>
                <template v-else>
                  <td>{{ bill.bill_item }}</td>
                </template>
                <td>{{ bill.date }}</td>
                <template v-if="bill.bill_category == 'debit'">
                  <td>{{ bill.item_quantity }}</td>
                </template>
                <template v-else>
                  <td>N.A</td>
                </template>
                <template v-if="bill.bill_category == 'debit' && bill.type_bill == 'room'">
                  <td>{{ (bill.cost / bill.item_quantity).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') }}</td>
                </template>
                <template v-else-if="bill.bill_category == 'debit' && bill.type_bill == 'laundry'">
                  <td>{{ bill.cost.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') }}</td>
                </template>
                <template v-else-if="bill.bill_category == 'debit' && bill.type_bill == 'room_service'">
                  <td>{{ bill.cost.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') }}</td>
                </template>
                <template v-else>
                  <td>{{ bill.cost.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') }}</td>
                </template>
                <template v-if="bill.bill_category == 'debit'">
                  <td>{{ bill.discount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') }} (0%)</td>
                </template>
                <template v-else>
                  <td>{{ bill.special_discount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') }}</td>
                </template>
                <template v-if="bill.bill_category == 'debit'">
                  <td>{{ ((bill.cost / bill.item_quantity) * bill.item_quantity).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') }}</td>
                </template>
                <template v-else>
                  <td>(-){{ bill.cost.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') }}</td>
                </template>
              </tr>
            </tbody>
          </table>
          <hr style="margin-top: -20px" />

          <!-- <div style="float:right; font-size:18px; margin-right:10px; font-weight:bold;">Total Bill: {{resInfo.total_amount}}</div> -->

          <div style="text-align: right; font-size: 18px; margin-right: 10px">Sub Total: {{ (parseInt(roomCost) + laundryCalculated + roomServiceCalculated).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') }} BDT</div>

          <div style="text-align: right; font-size: 18px; margin-right: 10px">VAT({{ companyInfo.tax }}%): {{ (roomCost * (companyInfo.tax / 100)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') }} BDT</div>

          <div style="text-align: right; font-size: 18px; margin-right: 10px">Service Charge({{ companyInfo.service_charge }}%): {{ (roomCost * (companyInfo.service_charge / 100)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') }} BDT</div>

          <template v-if="laundryVatAmount != 0 || laundryServiceCharge != 0">
            <div style="text-align: right; font-size: 18px; margin-right: 10px">Laundry VAT & SD({{ laundryVatAmount }}% | {{ laundryServiceCharge }}%): {{ laundryCalculatedDuty.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') }} BDT</div>
          </template>
          <template v-if="roomServiceVat != 0 || roomServiceServiceCharge != 0">
            <div style="text-align: right; font-size: 18px; margin-right: 10px">RoomService VAT & SD({{ roomServiceVat }}%|{{ roomServiceServiceCharge }}%): {{ roomServiceCalculatedDuty.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') }} BDT</div>
          </template>

          <div style="text-align: right; font-size: 18px; margin-right: 10px">Discount(s):(-) {{ resInfo.previous_discounts.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') }} BDT</div>

          <div class="border mt-3 mb-2"></div>
          <p style="text-align: right; margin-right: 10px; margin-top: 20px; font-size: 18px">Grand Total: {{ (laundryCalculated + roomServiceCalculated + laundryCalculatedDuty + roomServiceCalculatedDuty + roomCost + roomCost * (companyInfo.tax / 100) + roomCost * (companyInfo.service_charge / 100) - resInfo.previous_discounts).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') }} BDT</p>
          <div class="border mt-3 mb-2"></div>
          <p style="text-align: right; margin-right: 10px; margin-top: 20px; font-size: 18px">Advance & Payment(s): (-) {{ (advanceCalculated + roomClearanceCalculated).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') }} BDT</p>

          <template v-if="resInfo.total_due != 0">
            <div class="border mt-3 mb-2"></div>
            <p style="text-align: right; margin-right: 10px; margin-top: 20px; font-size: 18px">
              Total Due(s):
              {{
                (laundryCalculated + roomServiceCalculated + laundryCalculatedDuty + roomServiceCalculatedDuty + roomCost + roomCost * (companyInfo.tax / 100) + roomCost * (companyInfo.service_charge / 100) - resInfo.previous_discounts - advanceCalculated - roomClearanceCalculated)
                  .toFixed(2)
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
              }}
              BDT
            </p>
          </template>
          <template v-else>
            <div class="border mt-3 mb-2"></div>
            <p style="text-align: right; margin-right: 10px; margin-top: 20px; font-weight: bold; font-size: 18px; color: red">PAID</p>
          </template>

          <!-- <button class="button" @click.prevent="generatePdf">generate pdf</button>
                  {{resInfo}} -->
          <button
            class="button"
            style="background-color: #099dd9; color: white"
            @click.prevent="
              (showPdf = true), dateCal();
              printPDF();
            "
          >
            Print
          </button>
        </div>
      </div>
      <div class="column is-6">
        <div class="box" style="height: 8vh; margin-right: 10px; margin-left: -5px">
          <div style="">
            <div style="float: left; font-size: 17px">
              <p>
                Due: {{ resInfo.total_due }} <span style="font-size: 14px">BDT</span><template v-if="amountDiscount != 0"> | P.D: {{ resInfo.total_due - amountDiscount }} <span style="font-size: 14px">BDT</span></template>
              </p>
            </div>

            <div style="float: right">
              <button class="button" style="margin-top: -1.2vh; width: 10px; color: #099dd9; background-color: white"><i class="fa-brands fa-flickr"></i></button>
            </div>
            <div style="float: right; font-size: 17px; margin-right: 10px">
              <p>Room Clearence: <span>N.C</span></p>
            </div>
          </div>
        </div>
        <div class="box" style="height: 76vh; margin-right: 10px; margin-left: -5px">
          <p style="font-weight: 700; font-size: 20px">Settlement</p>

          <div class="columns">
            <div class="column is-6">
              <p style="text-align: left">Special/Countertop Discount</p>
              <input class="input" type="text" placeholder="Amount" v-model="amountDiscount" style="width: 210px; float: left" />
            </div>
            <div class="column is-6">
              <p style="text-align: left">Remarks for special discount</p>
              <textarea class="input" type="textarea" placeholder="remarks" v-model="specialDiscountReason" style=""></textarea>
            </div>
          </div>

          <div class="tabs is-centered is-medium">
            <ul>
              <li v-bind:class="{ 'is-active': isA == 'cash' }"><a v-on:click="(isA = 'cash'), (trxType = 'cash')">Cash</a></li>
              <li v-bind:class="{ 'is-active': isA == 'card' }"><a v-on:click="(isA = 'card'), (trxType = 'card')">Card</a></li>
              <li v-bind:class="{ 'is-active': isA == 'bkash' }"><a v-on:click="(isA = 'bkash'), (trxType = 'bkash')">bkash</a></li>
            </ul>
          </div>
          <div>
            <div class="tab-contents">
              <div class="content" v-bind:class="{ 'is-active': isA == 'cash' }" style="height: 25vh">
                <div class="field is-horizontal">
                  <div class="field-label is-normal">
                    <label class="label" style="font-size: 18px; width: 145px">Amount:</label>
                  </div>
                  <div class="field-body">
                    <div class="field">
                      <p class="control">
                        <input class="input" type="text" placeholder="Credit amount" v-model="amount_credited" style="width: 200px" />
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="tab-contents">
              <div class="content" v-bind:class="{ 'is-active': isA == 'card' }" style="height: 25vh">
                <div class="field is-horizontal">
                  <div class="field-label is-normal">
                    <label class="label" style="font-size: 18px; width: 145px">Amount:</label>
                  </div>
                  <div class="field-body">
                    <div class="field">
                      <p class="control">
                        <input class="input" type="text" placeholder="Credit amount" v-model="amount_credited" style="width: 200px" />
                      </p>
                    </div>
                  </div>
                </div>

                <div class="field is-horizontal">
                  <div class="field-label is-normal">
                    <label class="label" style="font-size: 18px; width: 145px">Card no:</label>
                  </div>
                  <div class="field-body">
                    <div class="field">
                      <p class="control">
                        <input class="input" type="text" placeholder="Discount amount" v-model="trans_id" style="width: 200px" />
                      </p>
                    </div>
                  </div>
                </div>
                <div class="field is-horizontal">
                  <div class="field-label is-normal">
                    <label class="label" style="font-size: 18px; width: 145px">Date of expiry:</label>
                  </div>
                  <div class="field-body">
                    <div class="field">
                      <p class="control">
                        <input class="input" type="date" placeholder="Discount amount" v-model="doe_card" style="width: 200px" />
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="tab-contents">
              <div class="content" v-bind:class="{ 'is-active': isA == 'bkash' }" style="height: 25vh">
                <div class="field is-horizontal">
                  <div class="field-label is-normal">
                    <label class="label" style="font-size: 18px; width: 145px">Amount:</label>
                  </div>
                  <div class="field-body">
                    <div class="field">
                      <p class="control">
                        <input class="input" type="text" placeholder="Credit amount" v-model="amount_credited" style="width: 200px" />
                      </p>
                    </div>
                  </div>
                </div>

                <div class="field is-horizontal">
                  <div class="field-label is-normal">
                    <label class="label" style="font-size: 18px; width: 145px">Transaction id:</label>
                  </div>
                  <div class="field-body">
                    <div class="field">
                      <p class="control">
                        <input class="input" type="text" placeholder="Discount amount" v-model="trans_id" style="width: 200px" />
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <button class="button" @click.prevent="submitPayment()" style="background-color: #099dd9; color: white">Settle Bill</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from '../boot/axios';

import axios from '../boot/axios';
import { toast } from 'bulma-toast';
//import jsPDF from 'jspdf'
//import $ from 'jquery'
import html2pdf from 'html2pdf-jspdf2';

export default {
  props: {
    fromId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isA: 'cash',
      resInfo: '',
      email: '',
      firstName: '',
      lastName: '',
      address: '',
      phone: null,
      custId: null,
      checkInDate: null,
      checkInTime: null,
      checkOutDate: null,
      checkOutTime: null,
      custInfo: '',
      earlyCheckIn: null,
      earlyCheckOut: null,
      extraBreakfast: null,
      roomType: '',
      genderSelection: '',
      passport: '',
      nidDl: '',
      country: '',
      nationality: '',
      occupation: '',
      doi: '',
      dof: '',
      visa: '',
      vdoi: '',
      vdof: '',
      genderTypes: ['Mr.', 'Ms.', 'Mrs.'],
      showInfo: false,
      cardData: '',
      billData: [],
      billModal: false,
      showPayment: false,
      guestNo: '',
      childInfo: '',
      AdultsInfo: '',
      specialIns: '',
      tempSplit: [],
      roomNos: [],
      roomNosFlat: [],
      secondaryRooms: '',
      amountDiscount: 0,
      specialDiscountReason: '',

      due_amount: '',
      amount_credited: '',
      creditCalculated: '',
      roomCost: 0,
      companyInfo: '',
      statusFilter: 'Checked Out',
      showPdf: false,
      calcTime: '',
      calcDate: '',
      advanceCalculated: 0,
      roomClearanceCalculated: 0,
      doe_card: '',
      trans_id: '',
      addBillCalculated: 0,
      laundryCalculated: 0,
      laundryCalculatedDuty: 0,
      roomServiceCalculatedDuty: 0,
      roomServiceCalculated: 0,
      laundryVatAmount: 0,
      laundryServiceCharge: 0,
      roomServiceVat: 0,
      roomServiceServiceCharge: 0,
      logo: null,
      company: '',
    };
  },
  created() {
    this.getResInfo();
    this.getLogo();
    this.getCompany();
  },
  methods: {
    async getLogo() {
      await axios
        .get('auth/hotel_logo/1/')
        .then((res) => {
          this.logo = res.data.pic;
        })
        .catch((err) => console.log(err));
    },

    async getCompany() {
      await axios
        .get('/auth/company/1/')
        .then((res) => {
          this.company = res.data;
          console.log(this.company);
        })
        .catch((err) => console.log(err));
    },
    async getResInfo() {
      console.log(this.resInfo);
      await api
        .get(`api/reservation/${this.$route.params.id}/`)
        .then((res) => {
          this.resInfo = res.data;
          console.log(this.resInfo);
        })
        .catch((err) => {
          console.log(err);
        });
      this.checkInDate = this.resInfo.check_in_date;
      this.checkInTime = this.resInfo.check_in_time;
      this.checkOutDate = this.resInfo.check_out_date;
      this.checkOutTime = this.resInfo.check_out_time;
      this.guestNo = this.resInfo.no_guest;
      this.AdultsInfo = this.resInfo.adults;
      this.childInfo = this.resInfo.childs;
      this.extraBreakfast = this.resInfo.extra_breakfast;
      this.specialIns = this.resInfo.special_ins;
      console.log('info', this.resInfo);

      this.roomNos.push(this.resInfo.room);

      if (this.resInfo.secondary_room != null && this.resInfo.secondary_room != '') {
        this.tempSplit = this.resInfo.secondary_room.split(',');

        console.log('yo man', this.tempSplit);
      }
      this.roomNos.push(this.tempSplit);
      this.roomNosFlat = this.roomNos.flat();

      // get secondary reservation room
      await api
        .get(`api/reservation/?primary_room_no=${this.resInfo.room}`)
        .then((res) => {
          this.secondaryRooms = res.data;
          console.log('rooms sendondary', this.secondaryRooms);
        })
        .catch((err) => {
          console.log(err);
        });

      // await axios.get('/auth/currentuser/'
      //     ).then(res =>{
      //         console.log(res.data.role)
      //         this.userInfo = res.data.company
      //     }).catch(err => console.log(err))
      console.log(this.userInfo);

      await axios
        .get(`/auth/company/1/`)
        .then((res) => {
          this.companyInfo = res.data;
        })
        .catch((err) => console.log(err));
      console.log(this.companyInfo);

      //customer info
      await api
        .get(`api/customer/${this.resInfo.cust_id}/`)
        .then((res) => {
          this.custInfo = res.data;
          console.log(this.custInfo);
        })
        .catch((err) => {
          console.log(err);
        });
      this.firstName = this.custInfo.first_name;
      this.lastName = this.custInfo.last_name;
      this.phone = this.custInfo.phone;
      this.email = this.custInfo.email;
      this.address = this.custInfo.address;
      this.genderSelection = this.custInfo.gender;
      this.passport = this.custInfo.passport;
      this.nidDl = this.custInfo.nid;
      this.dof = this.custInfo.passport_exp;
      this.visa = this.custInfo.visa_type;
      this.vdoi = this.custInfo.visa_issue;
      this.vdof = this.custInfo.visa_expiry;
      this.nationality = this.custInfo.nationality;
      this.country = this.custInfo.country;
      this.occupation = this.custInfo.occupation;

      await api.get(`api/card/?rev_id=${this.$route.params.id}`).then((res) => {
        this.cardData = res.data[0];
        console.log(this.cardData);
      });
      console.log('before bill');

      await this.getBills();

      await api.get(`api/room/${this.resInfo.room}/`).then((res) => {
        this.roomType = res.data;
        console.log('room', this.roomType);
      });
    },
    // async updateCheckIn(){
    //     console.log("update",this.roomType)

    //     if(this.resInfo.status != 'booked'){

    //         await api.put(`reservation/${this.$route.params.id}/`,{
    //             status: this.statusFilter,
    //             cust_id: this.resInfo.cust_id
    //         }).catch(err =>{
    //             console.log(err)
    //         })

    //         for(let i=0; i <this.secondaryRooms.length; i++){
    //             await api.put(`reservation/${this.secondaryRooms[i].id}/`,{
    //                 status: this.statusFilter,
    //                 cust_id: this.resInfo.cust_id
    //                 }).catch(err =>{
    //                     console.log(err)
    //             })
    //         }

    //         console.log('why i am not defined', this.roomNosFlat)

    //         for (let index = 0; index < this.roomNosFlat.length; index++) {
    //             await api.put(`room/${this.roomNosFlat[index]}/`,{
    //                 status_fd: 'available',
    //                 room_no: this.roomNosFlat[index]

    //                 }).catch(err=>{
    //                     console.log(err)
    //             })

    //         }
    //console.log(this.$route.params.id+this.resInfo.room)
    // let room = this.resInfo.room
    // let resID = this.$route.params.id
    // let cardString =room + resID.toString().padStart(5, '0')
    // console.log(cardString);

    // await api.post(`card/`,{
    //     card_no: cardString,
    //     room_no: this.roomType.room_no,
    //     cust_id: this.resInfo.cust_id,
    //     rev_id: this.$route.params.id

    // }).catch(err => {
    //     console.log(err)
    // })

    // }

    // },
    async getBills() {
      if (this.resInfo.previous_discounts == null) {
        this.resInfo.previous_discounts = 0;
      }

      //edit
      this.roomCost = 0;
      this.laundryCalculated = 0;
      this.roomServiceCalculated = 0;
      this.laundryCalculatedDuty = 0;
      this.roomServiceCalculatedDuty = 0;
      this.dayCount = 0;
      this.advanceCalculated = 0;
      this.roomClearanceCalculated = 0;
      await api.get(`api/card/?rev_id=${this.$route.params.id}`).then((res) => {
        this.cardData = res.data[0];
        console.log(this.cardData);
      });

      await api.get(`api/bills/?card_no=${this.cardData.card_no}`).then((res) => {
        this.billData = res.data;
        console.log(this.billData);
      });
      console.log('this bill', this.billData);

      for (let i = 0; i < this.billData.length; i++) {
        console.log(this.billData[i]);
        if (this.billData[i].bill_category == 'debit' && this.billData[i].type_bill == 'room_rent') {
          this.roomCost += parseInt((this.billData[i].cost / this.billData[i].item_quantity) * this.billData[i].item_quantity);
        }
        // if(this.billData[i].bill_category == 'credit'){
        //     this.roomCost -= parseInt(this.billData[i].cost)
        // }
      }
      console.table(this.billData);
      for (let i = 0; i < this.billData.length; i++) {
        if (this.billData[i].bill_category == 'credit' && this.billData[i].bill_item != 'Room Clearance') {
          this.advanceCalculated += parseInt(this.billData[i].cost);
        }
        if (this.billData[i].bill_category == 'credit' && this.billData[i].bill_item == 'Room Clearance') {
          this.roomClearanceCalculated += parseInt(this.billData[i].cost);
        }
      }

      for (let i = 0; i < this.billData.length; i++) {
        if (this.billData[i].bill_category == 'debit' && this.billData[i].type_bill == 'laundry') {
          console.log('yo yo', this.billData[i]);
          this.laundryCalculated += parseInt(this.billData[i].cost);
          this.laundryCalculatedDuty = (parseInt(this.billData[i].cost) * parseInt(this.billData[i].vat_amount)) / 100 + (parseInt(this.billData[i].cost) * parseInt(this.billData[i].sc_amount)) / 100;
          this.laundryVatAmount = parseInt(this.billData[i].vat_amount);
          this.laundryServiceCharge = parseInt(this.billData[i].sc_amount);
        }
        if (this.billData[i].bill_category == 'debit' && this.billData[i].type_bill == 'room_service') {
          this.roomServiceCalculated += parseInt(this.billData[i].cost);
          this.roomServiceCalculatedDuty = (parseInt(this.billData[i].cost) * parseInt(this.billData[i].vat_amount)) / 100 + (parseInt(this.billData[i].cost) * parseInt(this.billData[i].sc_amount)) / 100;
          this.roomServiceVat = parseInt(this.billData[i].vat_amount);
          this.roomServiceServiceCharge = parseInt(this.billData[i].sc_amount);
        }
      }

      console.log('lo lo', this.roomCost);
      console.log('prev discounts', this.resInfo);
      console.log('first');
      this.billModal = true;
    },
    // async getBills(){
    //     console.log('bill modal')
    //     await api.get(`card/?rev_id=${this.$route.params.id}`
    //     ).then(res => {
    //         this.cardData = res.data[0]
    //         console.log(this.cardData)
    //     })

    //     await api.get(`bills/?card_no=${this.cardData.card_no}`
    //     ).then(res =>{
    //         this.billData = res.data
    //         console.log("i am bill",this.billData)
    //     })
    //     this.billModal = true

    //     this.roomCost =0
    //     this.advanceCalculated =0
    //     this.roomClearanceCalculated =0

    // for(let i=0; i< this.billData.length; i++){
    //     console.log(this.billData[i])
    //     if(this.billData[i].bill_category == 'debit'){
    //         this.roomCost +=  parseInt((this.billData[i].cost / this.billData[i].item_quantity)*this.billData[i].item_quantity)
    //     }
    //     // if(this.billData[i].bill_category == 'credit'){
    //     //     this.roomCost -= parseInt(this.billData[i].cost)
    //     // }

    // }
    // for(let i=0; i< this.billData.length; i++){

    //     if(this.billData[i].bill_category == 'credit' && this.billData[i].bill_item != 'Room Clearance'){
    //         this.advanceCalculated += parseInt(this.billData[i].cost)
    //     }
    //     if(this.billData[i].bill_category == 'credit' && this.billData[i].bill_item == 'Room Clearance'){
    //         this.roomClearanceCalculated += parseInt(this.billData[i].cost)
    //     }

    // }

    // },
    printPDF() {
      this.showPdf = true;
      var element = document.getElementById('pdfportion');

      var opt = {
        margin: 0.2,
        filename: '1.pdf',
        image: { type: 'png' },
        html2canvas: { scale: 2 },
        pagebreak: { mode: 'avoid-all' },
        jsPDF: {
          orientation: 'p',
          unit: 'in',
          format: 'a4',
          putOnlyUsedFonts: true,
          floatPrecision: 16,
        },
      };

      html2pdf().set(opt).from(element).save();
      this.showPdf = false;
    },
    //     async submitPayment(){
    //         //edit here
    //         console.log(this.resInfo.total_due)
    //         console.log(this.amount_credited)
    //         this.due_amount = this.resInfo.total_due-this.amount_credited
    //         console.log(this.due_amount)
    //         this.creditCalculated = parseInt(this.resInfo.total_credit) + parseInt(this.amount_credited)
    //         console.log(this.creditCalculated)

    //         await api.put(`reservation/${this.resInfo.id}/`,{
    //             cust_id: this.resInfo.cust_id,
    //             status: this.resInfo.status,
    //             total_due: this.due_amount,
    //             total_credit: this.creditCalculated,
    //             previous_discounts: parseInt(this.resInfo.previous_discounts) + parseInt(this.amountDiscount)
    //         }).catch(err => console.log(err))

    //             if(this.trxType == 'cash'){
    //                 await api.post(`roombills/`,{
    //                 card_no: this.cardData.card_no,
    //                 amount: this.amount_credited,
    //                 trx_no : this.trans_id,
    //                 card_validity: this.doe_card,
    //                 trx_type : 'cash',
    //                 special_discount: this.amountDiscount,
    //                 special_discount_reason: this.specialDiscountReason
    //                 }).catch(err => console.log(err))

    //             }
    //             if(this.trxType =='card'){
    //                 await api.post(`roombills/`,{
    //                 card_no: this.cardData.card_no,
    //                 amount: this.amount_credited,
    //                 trx_no : this.trans_id,
    //                 card_validity: this.doe_card,
    //                 trx_type : 'card',
    //                 special_discount: this.amountDiscount,
    //                 special_discount_reason: this.specialDiscountReason
    //                 }).catch(err => console.log(err))

    //             }
    //             if(this.trxType == 'bkash'){
    //                 await api.post(`roombills/`,{
    //                 card_no: this.cardData.card_no,
    //                 amount: this.amount_credited,
    //                 trx_no :this.trans_id,
    //                 card_validity: this.doe_card,
    //                 trx_type : 'bkash',
    //                 special_discount: this.amountDiscount,
    //                 special_discount_reason: this.specialDiscountReason
    //                 }).catch(err => console.log(err))

    //             }
    //             this.getResInfo()
    // },
    async submitPayment() {
      console.log(this.resInfo.total_due);
      console.log(this.amount_credited);
      this.due_amount = parseInt(this.resInfo.total_due) - this.amountDiscount - this.amount_credited;
      console.log(this.due_amount);
      this.creditCalculated = parseInt(this.resInfo.total_credit) + parseInt(this.amount_credited);
      console.log(this.creditCalculated);

      await api
        .put(`api/reservation/${this.resInfo.id}/`, {
          cust_id: this.resInfo.cust_id,
          status: this.resInfo.status,
          total_due: this.due_amount,
          total_credit: this.creditCalculated,
          previous_discounts: parseInt(this.resInfo.previous_discounts) + parseInt(this.amountDiscount),
        })
        .then(
          toast({
            message: 'Payment has been updated',
            type: 'is-info',
            dismissible: true,
            pauseOnHover: true,
            duration: 2000,
            position: 'bottom-right',
          })
        )
        .catch((err) => console.log(err));

      if (this.trxType == 'cash') {
        await api
          .post(`api/bills/`, {
            cost: this.amount_credited,
            trx_no: this.trans_id,
            special_discount: this.amountDiscount,
            special_discount_reason: this.specialDiscountReason,
            card_validity: this.doe_card,
            trx_type: 'cash',
            //
            type_bill: 'room',
            is_paid: false,
            bill_item: `Room Clearance`,
            card_no: this.cardData.card_no,
            bill_category: 'credit',
          })
          .catch((err) => console.log(err));
      }
      if (this.trxType == 'card') {
        await api
          .post(`api/bills/`, {
            cost: this.amount_credited,
            trx_no: this.trans_id,
            special_discount: this.amountDiscount,
            special_discount_reason: this.specialDiscountReason,
            card_validity: this.doe_card,
            trx_type: 'card',
            //
            type_bill: 'room',
            is_paid: false,
            bill_item: `Room Clearance`,
            card_no: this.cardData.card_no,
            bill_category: 'credit',
          })
          .catch((err) => console.log(err));
      }
      if (this.trxType == 'bkash') {
        await api
          .post(`api/bills/`, {
            cost: this.amount_credited,
            trx_no: this.trans_id,
            special_discount: this.amountDiscount,
            special_discount_reason: this.specialDiscountReason,
            card_validity: this.doe_card,
            trx_type: 'bkash',
            //
            type_bill: 'room',
            is_paid: false,
            bill_item: `Room Clearance`,
            card_no: this.cardData.card_no,
            bill_category: 'credit',
          })
          .catch((err) => console.log(err));
      }
      this.jeAddForMoreCredit();

      this.getResInfo();
    },
    async jeAddForMoreCredit() {
      let dataTT = [];
      let dataCAO = [];
      // let user = [];
      // await axios
      //     .get(`auth/currentuser/`)
      //     .then(response => {
      //         user = response.data
      //     })
      //     .catch(error => {
      //             console.log(error)
      //         })
      await api
        .get('api/Transaction_table2/')
        .then((response) => {
          dataTT = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
      await api
        .get('api/coa/')
        .then((response) => {
          dataCAO = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
      let c = 1;
      if (!dataTT.length == 0) {
        c = parseInt(dataTT[dataTT.length - 1].journal_id) + 1;
      }
      let q = 0;
      for (let i = 0; i < dataCAO.length; i++) {
        if (dataCAO[i].cost_center === 'room_clearance' && dataCAO[i].type === 'Credit') {
          console.log(dataCAO[i].id);
          q = dataCAO[i].id;
        }
      }
      const a = {
        description: 'Room Clearance', //change
        date: new Date().toISOString().substr(0, 10),
        account_id: q,
        credited_by: 'Frontdesk',
        reference: this.cardData.card_no, //change
        credit: this.amount_credited, //change
        debit: 0, //change
        memo: 'R' + this.resInfo.room, //change
        journal_id: c,
        auto_journal: true,
      };
      await api.post('api/Transaction_table/', a).catch((err) => {
        console.log(err);
      });
    },
    async submitCheckOut() {
      console.log('update', this.roomType);

      if (this.resInfo.status != 'booked') {
        await api
          .put(`api/reservation/${this.$route.params.id}/`, {
            status: this.statusFilter,
            cust_id: this.resInfo.cust_id,
            bill_cleared: true,
          })
          .then(
            toast({
              message: 'Room(s) are checked out',
              type: 'is-info',
              dismissible: true,
              pauseOnHover: true,
              duration: 2000,
              position: 'bottom-right',
            })
          )
          .catch((err) => {
            console.log(err);
          });

        for (let i = 0; i < this.secondaryRooms.length; i++) {
          await api
            .put(`api/reservation/${this.secondaryRooms[i].id}/`, {
              status: this.statusFilter,
              cust_id: this.resInfo.cust_id,
            })
            .catch((err) => {
              console.log(err);
            });
        }

        console.log('why i am not defined', this.roomNosFlat);
        console.log(this.resInfo);
        if (this.resInfo.secondary_room == null || this.resInfo.secondary_room == '') {
          this.roomNosFlat.push(this.resInfo.room);
        }
        console.log('why i am not defined', this.roomNosFlat);

        for (let index = 0; index < this.roomNosFlat.length; index++) {
          await api
            .put(`api/room/${this.roomNosFlat[index]}/`, {
              status_fd: 'available',
              room_no: this.roomNosFlat[index],
            })
            .catch((err) => {
              console.log(err);
            });
        }

        await api
          .get(`api/card/?rev_id=${this.$route.params.id}`)
          .then((res) => {
            this.cardData = res.data[0];
            console.log(this.cardData);
          })
          .catch((err) => console.log(err));

        await api
          .put(`api/card/${this.cardData.card_no}`, {
            card_no: this.cardData.card_no,
            checked_in: 'false',
          })
          .catch((err) => console.log(err));

        this.$router.push({ name: 'Reservation' });
      }
    },
    generatePdf() {},
    dateCal() {
      this.calcDate = new Date().toLocaleString('en-US', { timeZone: 'Asia/Dhaka' }).substr(0, 9);
      this.calcTime = new Date().toLocaleString('en-US', { timeZone: 'Asia/Dhaka' }).substr(10, 11);
    },
  },
};
</script>

<style>
.tab-contents .content {
  display: none;
}
.tab-contents .content.is-active {
  display: block;
}

.button:hover {
  transform: scale(1.1);
}
.button {
  transition: all 0.2s ease-in-out;
}
</style>